<template>
  <div class="main-container main-text">
    <span class="bold-text">Точный диагноз<br>и бережное лечение</span>
    <span class="secondary-text">Сеть специализированных клиник</span>
  </div>
</template>

<style scoped lang="scss">
.main-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 60px;
  margin-bottom: 56px;
  @media (max-width: 1023px) {
    margin-top: 56px;
    margin-bottom: 24px;
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
  & .bold-text {
    font-family: 'Roboto';
    color: #262633;
    text-align: center;
     /* media width > 1024px */
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.72px;
    @media (max-width: 1023px) {
      font-size: 2.125rem;
      line-height: 40px;
      letter-spacing: 0.085px;
    }
    @media (max-width: 767px) {
      font-size: 1.75rem;
      line-height: 34px;
      letter-spacing: 0.07px;
    }
  }
  & .secondary-text {
    color: #666E81;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    @media (max-width: 767px) {
      font-size: 0.875rem;
      line-height: 18px;
    }
  }
}
</style>
